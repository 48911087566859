@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  /* overflow-y: scroll;
  scrollbar-width: none; */
  background-image: url("./assets/background.png");
}

body::-webkit-scrollbar {
  /* width: 0;
  height: 0; */
}

.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero {
  background-image: url("./assets/hero_bg.jpg");
}

.program {
  background-image: url("./assets/program_bg.png");
}
.timeline {
  background-image: url("./assets/timeline.jpg");
  background-position: center;
}

.challenge {
  background-image: url("./assets/challenge.png");
}

.innovation {
  background-image: url("./assets/innovation.jpg");
}

.solution {
  background-image: url("./assets/home_solution.jpg");
}

.join {
  background-image: url("./assets/join.jpg");
}

.why_join {
  background-image: url("./assets/Why_Join.jpg");
  background-size: cover;
  background-position: center;
}

.contact {
  background-image: url("./assets/contact.jpg");
}

.process {
  background-image: url("./assets/timeline.jpg");
}

.eligibility {
  background-image: url("./assets/eligibility.jpg");
}

.btn_arrow {
  /* margin-left: -10px; */
  opacity: 0;
  width: 0;
  overflow: hidden;
  transition: 0.5s;
}

.btn:hover .btn_arrow {
  /* width: 30px; */
  width: auto;
  margin-left: 10px;
  opacity: 1;
}

.blog_description p > span {
  font-size: 20px !important;
}
.blog_description h1 > span,
.blog_description h2 > span,
.blog_description h3 > span {
  font-size: 20px !important;
  display: inline-block;
  padding: 10px 0 !important;
}

.blog_description ul {
  list-style: disc;
  display: block;
  padding: 10px 0 10px 20px;
}

.blog_description li {
  padding-left: 8px !important;
}
.blog_description li span {
  font-size: 20px !important;
}

.blog_description li {
  padding-left: 8px !important;
}

.blog_description ol {
  list-style: decimal;
  display: block;
  padding: 10px 0 10px 20px;
}

.slick-slider {
  padding: 20px 80px;
}

.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  font-size: 40px;
}

.slick-slider .slick-prev {
  left: 30px;
  top: 55%;
}

.slick-slider .slick-next {
  right: 50px;
  top: 55%;
}

.slick-dots {
  display: none !important;
}

/* Card Hover effect */
.card_container {
  perspective: 800px;
}

.card:hover {
  cursor: pointer;
  transform: rotateY(180deg);
}

.card {
  min-height: 280px;
  height: 100%;
  width: 100%;
  position: relative;
  transition: transform 1500ms;
  transform-style: preserve-3d;
}

.front,
.back {
  height: 100%;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 0 5px 2px rgba(50, 50, 50, 0.25);
  position: absolute;
  backface-visibility: hidden;
}

.front {
  /* background-image: url(https://source.unsplash.com/random/350x500); */
}

.back {
  background-color: #ffc52f;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
